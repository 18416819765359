import { useSelector, useDispatch } from "react-redux"
import { createAsyncThunk, createSlice, isRejectedWithValue } from "@reduxjs/toolkit";
import axiosObj from './axios';
import Client from "shopify-buy";

// Creates the client with Shopify-Buy and store info
const client = Client.buildClient({
  domain: "shop.nestingale.com",
  storefrontAccessToken: "aacf6bb4a693f1477688cb1098928f9b",
});



const initialState = {
  cartCount: 0,
  checkout: {},
  guestCheckout: {},
  products: [],
  featured: [],
  product: {},
  shop: {},
  dbAddress: {}
};

export const getProducts = createAsyncThunk(
  "shopify/getProductsData",
  async (config, { rejectWithValue }) => {
	try{
		const products = await client.product.fetchAll();
		return products.map((product) => ({
		id: product.id,
		name: product.title,
		description: product.description,
		price: {
			amount: product.variants[0].price.amount,
			currencyCode: product.variants[0].price.currencyCode,
			type: product.variants[0].price.type,
		},
		image: product.images[0].src,
		}));
	}catch(e){
		return rejectWithValue(e);
	}
  }
);

export const getProduct = createAsyncThunk(
  "shopify/getProductData",
  async (id, { rejectWithValue }) => {
	try{
		return client.product.fetch(id);
	}catch(e){
		return rejectWithValue(e);
	}
  }
);

export const checkout = createAsyncThunk(
  "shopify/checkoutData",
  async (config, { rejectWithValue }) => {
	try{
		let checkoutId = config.checkoutId;
		if(!checkoutId){
			const checkoutResponse = await client.checkout.create();
			checkoutId = checkoutResponse.id;
		}
    	// Get the checkout details using the created checkout ID
    	const checkoutDetailsResponse = await client.checkout.fetch(checkoutId);
		//convert the GraplQL objeft to normal JSON
    	return JSON.parse(JSON.stringify(checkoutDetailsResponse));
	}catch(e){
		return rejectWithValue(e);
	}
  }
);

export const shopInfo = createAsyncThunk(
  "shopify/shopInfoData",
  async (config, { rejectWithValue }) => {
	try{
    	return client.shop.fetchInfo();
	}catch(e){
		return rejectWithValue(e);
	}
  }
);

export const addVariantToCart = createAsyncThunk(
	"shopify/addVariantToCartData",
	async ({ checkoutId, lineItemsToAdd, successCallback }, { rejectWithValue }) => {
		try{
			const response = await client.checkout.addLineItems(
				checkoutId,
				lineItemsToAdd
			  );
			  return JSON.parse(JSON.stringify(response));
		}catch(e){
			return rejectWithValue(e);
		}
	}
  );
  
  export const updateQuantityInCart = createAsyncThunk(
	"shopify/updateQuantityInCartData",
	async ({ lineItemId, quantity, checkoutID }, { rejectWithValue }) => {

		try{
			const lineItemsToUpdate = [
				{ id: lineItemId, quantity: parseInt(quantity, 10) },
			  ];
			  const resp = await client.checkout.updateLineItems(
				checkoutID,
				lineItemsToUpdate
			  );
			  return JSON.parse(JSON.stringify(resp));
		}catch(e){
			return rejectWithValue(e);
		}
	  
	}
  );
  
  export const removeLineItemInCart = createAsyncThunk(
	"shopify/removeLineItemInCartData",
	async ({ checkoutID, lineItemId }, { rejectWithValue }) => {
		try{
			const resp = await client.checkout.removeLineItems(checkoutID, [lineItemId]);
	  		return JSON.parse(JSON.stringify(resp));
		}catch(e){
			return rejectWithValue(e);
		}
	  
	}
  );

  export const updateEmailAddressInShopify = createAsyncThunk(
	"shopify/updateEmailAddressInShopifyData",
	async ({ checkoutId, emamilAddress }, { rejectWithValue }) => {
	    if (!email) return;
		try{
			const response = await client.checkout.updateEmail(
				checkoutId,
				emamilAddress
			  );
			  return JSON.parse(JSON.stringify(response));
		}catch(e){
			return rejectWithValue(e);
		}
	}
  );

  
  export const updateShippingAddressInShopify = createAsyncThunk(
	"shopify/updateShippingAddressInShopifyData",
	async ({ checkoutId,  shippingAddress }, { rejectWithValue }) => {
		try{
			if(!shippingAddress["addressList"] || shippingAddress["addressList"].length == 0) {
				console.log("No addressList for this user");
				return;
			}
			const defaultTrueAddress  = shippingAddress.addressList.filter((eachAddress) => eachAddress.default)

			let latestAddress  = defaultTrueAddress[0]
			const shippingAddressToSend = {
				address1: latestAddress.address1,
				address2: latestAddress.address2,
				city: latestAddress.city,
				company: latestAddress.company,
				country: latestAddress.country,
				firstName: latestAddress.first_name,
				lastName: latestAddress.last_name,
				phone: latestAddress.phone,
				province: latestAddress.province,
				zip: latestAddress.zip 
			  };
			 
			// Update the shipping address for an existing checkout.
			const response = await client.checkout.updateShippingAddress(checkoutId, shippingAddressToSend);
			return JSON.parse(JSON.stringify(response));
		}catch(e){
			return rejectWithValue(e);
		}
	}
  );

  export const getShippingAddressInShopify = createAsyncThunk(
	"shopify/getShippingAddressInShopifyData",
	async ({ customerId , brand}, { rejectWithValue }) => {
		try {
			const headers =  {
				'content-type': "application/json"
			}
			let url  = `/api/customer/addresses?userId=${customerId}` 
			if(brand && brand !== 'nestingale'){
				url += "&vendor="+ brand 
			}
			const addressData = await axiosObj.get(url)
				.then((response) => {
					return response.data
				},(err)=>{
					const errorObj = Object.assign({description: "NEST_SPA:Slices:getShippingAddressInShopify:Exception", errorCode: "NEST_SPA:HOME_PAGE"},err)
					console.log(errorObj);
					return rejectWithValue(err);
					//return welcomeProfile;
				});

				console.log("addressData=",addressData)
			return addressData;
		} catch (err) {
			console.log({description: "NEST_SPA:Slices:getShippingAddressInShopify:Exception", errorCode: "NEST_SPA:HOME_PAGE", err_desc: err.message, err_stack: err.stack});
			return rejectWithValue(err);
		}
	}
  ); 


const shopifySlice = createSlice({
  name: "shopify",
  initialState,
  reducers: {
    handleSetCount: (state, action) => {
      state.cartCount = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProducts.fulfilled, (state, action) => {
        state.products = action.payload;
      })
      .addCase(getProduct.fulfilled, (state, action) => {
        state.product = action.payload;
      })
      .addCase(checkout.fulfilled, (state, action) => {
		if(action.meta.arg.guest){
			state.guestCheckout = action.payload;
		}else{
			state.checkout = action.payload;
		}
      })
      .addCase(shopInfo.fulfilled, (state, action) => {
        state.shop = action.payload;
      })
      .addCase(addVariantToCart.fulfilled, (state, {meta, payload}) => {
        state.checkout = payload;
		checkAndNavigateToCart(meta.arg.successCallback);
      })
      .addCase(updateQuantityInCart.fulfilled, (state, action) => {
        state.checkout = action.payload;
      })
      .addCase(removeLineItemInCart.fulfilled, (state, action) => {
        state.checkout = action.payload;
      })
	  .addCase(getShippingAddressInShopify.fulfilled, (state, action) => {
        state.dbAddress = action.payload;
      });
  },
});

export const {
  handleSetCount
} = shopifySlice.actions;

function checkAndNavigateToCart(callback) {
	if(callback){
		callback(true);
	}
}

export function useShopify() {
	const dispatch = useDispatch()
	const cartStatus = useSelector((state) => true)
	const cartCount = useSelector((state) => state.shopifyReducer.cartCount)
	const products = useSelector((state) => state.shopifyReducer.products)
	const product = useSelector((state) => state.shopifyReducer.product)
	const featured = useSelector((state) => state.shopifyReducer.featured)
	const guestCheckoutState = useSelector((state) => state.shopifyReducer.guestCheckout);
	const checkoutState = useSelector(
		(state) => state.shopifyReducer.checkout
	)
	const checkoutID = useSelector(
		(state) => state.shopifyReducer?.checkout?.id
	)

	const dbAddressInfo =  useSelector((state)=> state.shopifyReducer.dbAddress)
	const shopDetails = useSelector((state) => state.shopifyReducer.shop)
	const fetchProducts = () => dispatch(getProducts())
	const fetchProduct = (id) => dispatch(getProduct(id))
	// const fetchCollection = () => dispatch(getCollection())
	const createCheckout = (config) => dispatch(checkout(config))
	const createShop = () => dispatch(shopInfo())
	const setCount = (count) => dispatch(handleSetCount(count))
    const getClient = () => client
	const addVariant = (checkoutId, lineItemsToAdd, successCallback) =>
		dispatch(addVariantToCart({ checkoutId, lineItemsToAdd, successCallback }))
	const updateQuantity = (lineItemId, quantity, checkoutID) =>
		dispatch(updateQuantityInCart({lineItemId, quantity, checkoutID}))
	const removeLineItem = (checkoutID, lineItemId) =>
		dispatch(removeLineItemInCart({checkoutID, lineItemId}))

    const fetchShippingAddressInShopify = (postData) => {
		dispatch(getShippingAddressInShopify(postData));
	}

	const saveShippingAddressInShopify = (postData) => {
		dispatch(updateShippingAddressInShopify(postData));
	}

	const saveEmailAddressInShopify = (postData) => dispatch(updateEmailAddressInShopify(postData));

	return {
		getClient,
		products,
		product,
		featured,
		cartStatus,
		checkoutState,
		guestCheckoutState,
		cartCount,
		shopDetails,
		addVariant,
		fetchProducts,
		fetchProduct,
		fetchShippingAddressInShopify,
		saveShippingAddressInShopify,
		saveEmailAddressInShopify,
		checkoutID,
		// fetchCollection,
		createCheckout,
		createShop,
		updateQuantity,
		removeLineItem,
		setCount,
		dbAddressInfo
	}
}


export default shopifySlice.reducer;

// // Creates the client with Shopify-Buy and store info
// const client = Client.buildClient({
// 	domain: '178f33-2.myshopify.com',
// 	storefrontAccessToken: 'aacf6bb4a693f1477688cb1098928f9b'
// });

// const PRODUCTS_FOUND = "shopify/PRODUCTS_FOUND"
// const PRODUCT_FOUND = "shopify/PRODUCT_FOUND"
// const COLLECTION_FOUND = "shopify/COLLECTION_FOUND"
// const CHECKOUT_FOUND = "shopify/CHECKOUT_FOUND"
// const SHOP_FOUND = "shopify/SHOP_FOUND"
// const ADD_VARIANT_TO_CART = "shopify/ADD_VARIANT_TO_CART"
// const UPDATE_QUANTITY_IN_CART = "shopify/UPDATE_QUANTITY_IN_CART"
// const REMOVE_LINE_ITEM_IN_CART = "shopify/REMOVE_LINE_ITEM_IN_CART"

// const CART_COUNT = "shopify/CART_COUNT"

// const initialState = {
// 	cartCount: 0,
// 	checkout: {},
// 	products: [],
// 	featured: [],
// 	product: {},
// 	shop: {},
// }

// const shopifyReducer = (state = initialState, action) => {

// 	switch (action.type) {
// 		case PRODUCTS_FOUND:
// 			debugger
// 			return { ...state, products: [{"a":"hello"}] } ;break;
// 		case PRODUCT_FOUND:
// 			return { ...state, product: action.payload }
// 		case COLLECTION_FOUND:
// 			return { ...state, featured: action.payload }
// 		case CHECKOUT_FOUND:
// 			return { ...state, checkout: action.payload }
// 		case SHOP_FOUND:
// 			return { ...state, shop: action.payload }
// 		case ADD_VARIANT_TO_CART:
// 			return { ...state, checkout: action.payload }
// 		case UPDATE_QUANTITY_IN_CART:
// 			return { ...state, checkout: action.payload }
// 		case REMOVE_LINE_ITEM_IN_CART:
// 			return { ...state, checkout: action.payload }
// 		case OPEN_CART:
// 			return { ...state, isCartOpen: true }
// 		case CLOSE_CART:
// 			return { ...state, isCartOpen: false }
// 		case CART_COUNT:
// 			return { ...state, cartCount: action.payload }
// 		default:
// 			debugger
// 			return state
// 	}
// }

// // Gets all the products from Shopify
// function getProducts() {
// 	return (dispatch) => {
// 		client.product.fetchAll().then((products) => {
			
// 			const transformedProducts = products.map((product) => {
				
// 				// Access product properties using the provided methods
// 				const productId = product.id;
// 				const productName = product.title;
// 				const productDescription = product.description;
// 				const productPrice = product.variants[0].price;
// 				const productImage = product.images[0].src;

// 				 // Extract properties from the price object
// 				 const priceAmount = productPrice.amount;
// 				 const priceCurrencyCode = productPrice.currencyCode;
// 				 const priceType = productPrice.type;
			
// 				// Return the extracted information as an object
// 				return {
// 				  id: productId,
// 				  name: productName,
// 				  description: productDescription,
// 				  price: {
// 					amount: priceAmount,
// 					currencyCode: priceCurrencyCode,
// 					type: priceType,
// 				  },
// 				  image: productImage,
// 				};
// 			  });
// 			  debugger
// 			dispatch({
// 				type: PRODUCTS_FOUND,
// 				payload: transformedProducts,
// 			})
// 		})
// 	}
// }

// // Gets individual item based on id
// function getProduct(id) {
// 	return async (dispatch) => {
// 		const resp = await client.product.fetch(id)
// 		dispatch({
// 			type: PRODUCT_FOUND,
// 			payload: resp,
// 		})
// 		return resp
// 	}
// }

// //
// // Gets a  collection based on that collection's id
// //
// // function getCollection() {
// // 	const collectionId = "Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzIwODAyMDYwMzAzMw=="
// // 	return (dispatch) => {
// // 		client.collection.fetchWithProducts(collectionId).then((resp) => {
// // 			dispatch({
// // 				type: COLLECTION_FOUND,
// // 				payload: resp.products,
// // 			})
// // 		})
// // 	}
// // }

// // Creates initial checkout state from Shopify
// function checkout() {
// 	return (dispatch) => {
// 		client.checkout.create().then((resp) => {
// 			dispatch({
// 				type: CHECKOUT_FOUND,
// 				payload: resp,
// 			})
// 		})
// 	}
// }

// // Gets Shopify store information
// function shopInfo() {
// 	return (dispatch) => {
// 		client.shop.fetchInfo().then((resp) => {
// 			dispatch({
// 				type: SHOP_FOUND,
// 				payload: resp,
// 			})
// 		})
// 	}
// }

// // Adds variants to cart/checkout
// function addVariantToCart(checkoutId, lineItemsToAdd) {
// 	return async (dispatch) => {
// 		const response = await client.checkout.addLineItems(
// 			checkoutId,
// 			lineItemsToAdd
// 		)
// 		dispatch({
// 			type: ADD_VARIANT_TO_CART,
// 			payload: response,
// 		})
// 		return response
// 	}
// }

// // Updates quantity of line items in cart and in checkout state
// function updateQuantityInCart(lineItemId, quantity, checkoutId) {
// 	const lineItemsToUpdate = [
// 		{ id: lineItemId, quantity: parseInt(quantity, 10) },
// 	]

// 	return async (dispatch) => {
// 		const resp = await client.checkout.updateLineItems(
// 			checkoutId,
// 			lineItemsToUpdate
// 		)
// 		dispatch({
// 			type: UPDATE_QUANTITY_IN_CART,
// 			payload: resp,
// 		})
// 		return resp
// 	}
// }

// // Removes line item from cart and checkout state
// function removeLineItemInCart(checkoutId, lineItemId) {
// 	return (dispatch) => {
// 		client.checkout.removeLineItems(checkoutId, [lineItemId]).then((resp) => {
// 			dispatch({
// 				type: REMOVE_LINE_ITEM_IN_CART,
// 				payload: resp,
// 			})
// 		})
// 	}
// }



// Set the count of items in the cart
// function handleSetCount(count) {
// 	return {
// 		type: CART_COUNT,
// 		payload: count,
// 	}
// }


