'use client';

import React, { useEffect, useRef, useState } from "react";
import ClientComponent from "./client_component";
import { Box, Button, Checkbox, Divider, Grid, Input, Stack, TextField, Tooltip, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { BuilderRoomType, Project, actions as builderActions } from "@/store/builderSlice";
import BuilderSelct from "@/store/builderStoreSelector";
import { BUILDER_FAILED, BUILDER_IN_PROGRESS, DimensionalViewType, ProductPlacementType, RoomViewType, SurfaceType, TRIGGER_ROOM_LAYOUT_THUMBNAILS_SAVE } from "@/utils/builder/builderConstants";
import { useSearchParams } from 'react-router-dom';
import ToggleButton from '@mui/material/ToggleButton';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { saveProject as saveProjectThunk } from "@/store/builder/thunks/projectSaveThunk";
import { isFeatureAvailable } from "@/utils/builder/featureCatalog";
import { isBuilderContextReady } from "@/config/getCachedBuilderContext";
import { BUILDER_SUCCESS } from '@/utils/builder/builderConstants';
import { saveRoomLayout, saveRoomLayoutThumbnail } from "@/store/builder/thunks/roomModelsThunk";
import cssStyles from './builder.module.scss';
import ProjectSummary from "./ProjectSummary";
import BuyNowComponent from "./BuyNowComponent";
import ViewInMyRoomButton from "./ViewInMyRoomButton";



const ControlsBar = () => {

    const dispatch = useDispatch();
    const projectNameRef = useRef<any | null>(null);
    const project: Project = BuilderSelct.project();
    const layoutBuilderMode: string = BuilderSelct.ui().layoutBuilderMode;
    const isCustomRoomFlow: boolean = BuilderSelct.ui().isCustomRoomFlow;
    const urlParams: { [key: string]: string } = BuilderSelct.ui().urlParams;
    const roomTypes: BuilderRoomType[] = BuilderSelct.roomTypes();
    const roomLabel: string = BuilderSelct.twoDRoomLayout().roomLabel || decodeURIComponent(urlParams.roomLabel);
    const roomType = BuilderSelct.twoDRoomLayout().roomType || urlParams.roomType;
    const captureThumbnailStatus = BuilderSelct.twoDRoomLayout().captureThumbnailStatus;
    const twoDLayoutSaveStatus = BuilderSelct.twoDRoomLayout().saveStatus;
    const thumbnailImage = BuilderSelct.twoDRoomLayout().thumbnailImage;
    const roomLabelRef = useRef<any | null>(null);
    const isRoomLabelUpdated = useRef(false);
    const canEditRoomLabel = project.roomModel.roomId == null || project.roomModel.roomId == '';
    const [showReviewLabelTooltip, setShowReviewLabelTooltip] = useState(false);
    const [showAddRoomTypeTooltip, setShowAddRoomTypeTooltip] = useState(false);
    const [roomTypeState, setRoomTypeState] = useState(roomType || '');
    const roomTypeRef = useRef<any | null>(null);
    const is2DLayoutFlow = urlParams.createRoom === 'true' || (isCustomRoomFlow && layoutBuilderMode === DimensionalViewType.TWO_D);
    const [isIOSWebKit, setIsIOSWebKit] = useState(false);
    const switchedTo2DModeRef = useRef(false);
    const editPreviewLabel = layoutBuilderMode === DimensionalViewType.TWO_D ? 'Preview' : switchedTo2DModeRef.current ? 'Edit' : 'Create your room plan';


    const getWindowDimensions = () => {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height
        };
    };
    const useWindowDimensions = () => {
        const [windowDimensions, setWindowDimensions] = useState(
            getWindowDimensions()
        );
        useEffect(() => {
            function handleResize() {
                setWindowDimensions(getWindowDimensions());
            }
            window.addEventListener("resize", handleResize);
            return () => window.removeEventListener("resize", handleResize);
        }, []);
        return windowDimensions;
    };
    const windowWidth = useWindowDimensions().width;

    useEffect(() => {
        if (typeof window !== 'undefined' && (window as any).webkit) {
            setIsIOSWebKit(true);
        }
    }, []);

    useEffect(() => {
        if (projectNameRef.current) {
            (projectNameRef.current.childNodes[0] as HTMLInputElement).value = project.projectName;
        }
    }, [project.projectName]);

    useEffect(() => {
        if (roomLabelRef.current) {
            (roomLabelRef.current.childNodes[0] as HTMLInputElement).value = decodeURIComponent(roomLabel);
        }
    }, [roomLabel]);

    useEffect(() => {
        document.body.classList.add("builder-page");
        return () => {
            document.body.classList.remove("builder-pagee");
        };
    }, []);

    const changeLayoutBuilderMode = (event: any, value: string) => {
        dispatch(builderActions.updateLayoutBuilderMode({ layoutBuilderMode: value }));
    }

    const updateBuilderLayoutMode = () => {
        const targetMode = layoutBuilderMode === DimensionalViewType.TWO_D ? DimensionalViewType.THREE_D : DimensionalViewType.TWO_D;
        if (!switchedTo2DModeRef.current && targetMode === DimensionalViewType.TWO_D)
            dispatch(builderActions.updateTwoDUi({
                showLayoutInfoTooltip: true
            }));
        if (layoutBuilderMode === DimensionalViewType.THREE_D)
            switchedTo2DModeRef.current = true;
        if (layoutBuilderMode === DimensionalViewType.TWO_D)
            dispatch(builderActions.updateRoomLayoutThumbnailCaptureStatus({ captureThumbnailStatus: 'start' }));
        else
            dispatch(builderActions.updateLayoutBuilderMode({ layoutBuilderMode: targetMode }));
    }

    const saveProjectName = (event: any) => {
        dispatch(builderActions.updateProjectName({
            projectName: event.target.value
        }));
    }

    const updateRoomLabel = (event: any) => {
        isRoomLabelUpdated.current = true;
        setShowReviewLabelTooltip(false);
        dispatch(builderActions.updateRoomLabel({
            roomLabel: event.target.value
        }));
    }


    const editProjectName = (event: any) => {
        (projectNameRef.current.childNodes[0] as HTMLInputElement).focus();
    }

    const editRoomLabel = (event: any) => {
        (roomLabelRef.current.childNodes[0] as HTMLInputElement).focus();
    }

    const addCornerToRoom = () => {
        dispatch(builderActions.addRoomCornerVertex({
            undoable: true
        }));
    }

    const saveRoomModelAndNavigateToQuestionnairePage = () => {
        if (!isRoomLabelUpdated.current && (project.roomModel.roomId === '' || project.roomModel.roomId == null)) {
            setShowReviewLabelTooltip(true);
            (roomLabelRef.current.childNodes[0] as HTMLInputElement).focus();
            return;
        }
        if (roomTypeState == null || roomTypeState === '') {
            setShowAddRoomTypeTooltip(true);
            (roomTypeRef.current.childNodes[0] as HTMLSelectElement).focus();
            return;
        }
        dispatch(saveRoomLayout({}) as any);
    }

    const onRoomTypeSelection = (event: SelectChangeEvent) => {
        let newRoomLabel = undefined;
        if (roomLabel.indexOf('undefined') != -1) {
            newRoomLabel = roomLabel.replace('undefined', event.target.value);
        }
        dispatch(builderActions.updateRoomLabel({
            roomType: event.target.value,
            roomLabel: newRoomLabel
        }));
        if (showAddRoomTypeTooltip)
            setShowAddRoomTypeTooltip(false);
    };

    const resetTooltip = () => {
        if (showAddRoomTypeTooltip)
            setShowAddRoomTypeTooltip(false);
    }

    useEffect(() => {
        if (roomType && roomType != roomTypeState)
            setRoomTypeState(roomType)
    }, [roomType]);

    useEffect(() => {
        if (captureThumbnailStatus === 'end') {
            dispatch(builderActions.updateLayoutBuilderMode({ layoutBuilderMode: DimensionalViewType.THREE_D }));
            dispatch(builderActions.updateRoomLayoutThumbnailCaptureStatus({ captureThumbnailStatus: '' }));
        }
    }, [captureThumbnailStatus]);

    useEffect(() => {
        if (twoDLayoutSaveStatus === TRIGGER_ROOM_LAYOUT_THUMBNAILS_SAVE && layoutBuilderMode === DimensionalViewType.THREE_D && thumbnailImage != null && thumbnailImage !== '') {
            dispatch(saveRoomLayoutThumbnail({ image: thumbnailImage }) as any);
        }
    }, [twoDLayoutSaveStatus, layoutBuilderMode]);

    const builderModeLayoutToggle = <Grid item style={{ margin: '0 auto' }}>
        <ToggleButtonGroup
            value={layoutBuilderMode}
            exclusive
            onChange={changeLayoutBuilderMode}
            aria-label="text alignment"
        >
            <ToggleButton value={DimensionalViewType.TWO_D}>
                <Typography fontSize={12}>2D</Typography>
            </ToggleButton>
            <ToggleButton value={DimensionalViewType.THREE_D}>
                <Typography fontSize={12}>3D</Typography>
            </ToggleButton>
        </ToggleButtonGroup>
    </Grid>;

    console.log('debug:: ', roomTypeState, roomType);
    const get2DControls = () => {
        return <>
            {!isIOSWebKit && windowWidth >= 768 && (
                <ViewInMyRoomButton forceVisible={true} buttonLabel={'Download app'} />
            )}
            <Grid item>
                <Tooltip
                    title={<Typography fontSize={14} color={'red'}>Review the room label before saving</Typography>}
                    arrow
                    PopperProps={{
                        style: { zIndex: 99999999, width: 200, opacity: 1, color: '#D35400' },
                        modifiers: [
                            {
                                name: "offset",
                                options: {
                                    offset: [0, -15],
                                },
                            },
                        ],
                    }}
                    placement="bottom"
                    open={showReviewLabelTooltip}
                >
                    <Stack spacing={0} direction="row" sx={{ alignItems: 'center' }}>
                        <Box component={'div'}>
                            <Input
                                defaultValue={decodeURIComponent(roomLabel)}
                                sx={{ '& input': { textAlign: 'center', fontSize: 16, fontWeight: 'bold', width: 'auto' } }}
                                ref={roomLabelRef}
                                //readOnly={!canEditRoomLabel}
                                onBlur={updateRoomLabel}
                            />
                        </Box>
                        {/* {canEditRoomLabel && <EditOutlinedIcon
                            fontSize="large"
                            onClick={editRoomLabel}
                            sx={{
                                color: 'black',
                                border: "solid 1px black",
                                padding: '4px',
                                borderRadius: '100%',
                                width: '1.5em',
                                height: '1.5em',
                                marginLeft: '10px',
                                cursor: 'pointer'
                            }}
                        />} */}
                    </Stack>
                </Tooltip>
            </Grid>
            {(urlParams.roomType == null || urlParams.roomType === '') && !isIOSWebKit &&
                <Grid item>
                    <Tooltip
                        title={<Typography fontSize={14} color={'red'}>Select room type before saving</Typography>}
                        arrow
                        PopperProps={{
                            style: { zIndex: 99999999, width: 200, opacity: 1, color: '#D35400' },
                            modifiers: [
                                {
                                    name: "offset",
                                    options: {
                                        offset: [0, -15],
                                    },
                                },
                            ],
                        }}
                        placement="bottom"
                        open={showAddRoomTypeTooltip}
                    >
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <Select
                                value={roomTypeState}
                                onChange={onRoomTypeSelection}
                                ref={roomTypeRef}
                                onOpen={resetTooltip}
                                displayEmpty
                            >
                                <MenuItem value="">
                                    <em><Typography fontSize={12}>Select room type</Typography></em>
                                </MenuItem>
                                {roomTypes.map(roomTypeObj =>
                                    <MenuItem key={roomTypeObj.roomType} value={roomTypeObj.roomType}>
                                        <Typography fontSize={12}>{roomTypeObj.roomTypeDisplayName}</Typography>
                                    </MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Tooltip>
                </Grid>
            }
            {windowWidth >= 768 && <>
                <Grid item>
                    <Box component={'div'}
                        sx={{ marginRight: '20px' }}
                        visibility={is2DLayoutFlow ? 'visible' : 'hidden'}
                    > <Button
                        variant="contained"
                        color="primary"
                        onClick={updateBuilderLayoutMode}
                        size="small"
                    >
                            {editPreviewLabel}
                        </Button>
                    </Box>
                </Grid>
                {(layoutBuilderMode === DimensionalViewType.TWO_D || thumbnailImage !== '') &&
                    <Grid item>
                        <Box component={'div'}
                            sx={{ marginRight: '20px' }}
                            visibility={is2DLayoutFlow ? 'visible' : 'hidden'}
                        > <Button
                            variant="contained"
                            color="primary"
                            onClick={saveRoomModelAndNavigateToQuestionnairePage}
                            size="small"
                        >
                                Save
                            </Button>
                        </Box>
                    </Grid>
                }
            </>}
        </>
    }

    const getControls = () => {
        return <>
            <Grid item style={{ margin: '0 auto' }}>
                <Stack spacing={0} direction="row">
                    <Input
                        defaultValue={decodeURIComponent(project.projectName)}
                        sx={{ '& input': { textAlign: 'right', fontSize: 16, fontWeight: 'bold' } }}
                        readOnly={!isFeatureAvailable('editableProjectName')}
                        ref={projectNameRef}
                        onBlur={saveProjectName}
                    />
                    {(isFeatureAvailable('editableProjectName') && !is2DLayoutFlow) && <EditOutlinedIcon
                        fontSize="large"
                        onClick={editProjectName}
                        style={{ color: 'black' }}
                    />}
                </Stack>
            </Grid>
            <ViewInMyRoomButton />
            <Grid item>
                <ProjectSummary />
            </Grid >
            <BuyNowComponent />
        </>
    }


    if (!isBuilderContextReady()) return <></>

    return (<Box component={'div'}
        sx={{
            height: '65px',
            display: 'flex',
            alignItems: 'center',
            width: '100%'
        }}
    >
        <Grid container justifyContent={windowWidth >= 768 ? 'right' : 'center'} alignItems="center">
            {is2DLayoutFlow ? (get2DControls()) : (getControls())}
        </Grid>




        {/* <Box component={'div'}
            sx={{
                p: 0,
                m: 1,
                bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : 'grey.100'),
                color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
                border: '1px solid',
                borderColor: (theme) =>
                    theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                borderRadius: 2,
                height: '30px'
            }}> <Button variant="contained" onClick={saveProject} size="small">Save</Button></Box>
        <div>
            <Box component={'div'}
                sx={{
                    p: 0,
                    m: 1,
                    bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : 'grey.100'),
                    color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
                    border: '1px solid',
                    borderColor: (theme) =>
                        theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                    borderRadius: 2,
                    height: '30px'
                }}> <Button disabled={!canUndo} variant="contained" onClick={undo} size="small">Undo</Button></Box>
        </div>
        <div>
            <Box component={'div'}
                sx={{
                    p: 0,
                    m: 1,
                    bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : 'grey.100'),
                    color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
                    border: '1px solid',
                    borderColor: (theme) =>
                        theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                    borderRadius: 2,
                    height: '30px'
                }}> <Button disabled={!canRedo} variant="contained" onClick={redo} size="small">Redo</Button></Box>
        </div>

        <div>
            &nbsp;&nbsp;Preview Mode :
            <Checkbox
                checked={isPreviewMode}
                onChange={togglePreviewMode}
            />
        </div>
        <Divider orientation="vertical" flexItem sx={{ borderRightWidth: 2 }} style={{ background: 'black' }} />
        <Box component={'div'} sx={{ width: 200 }}>
            <ToggleButtonGroup
                value={roomView}
                exclusive
                onChange={changeRoomView}
            >
                <ToggleButton value={RoomViewType.top} aria-label="left aligned">
                    Top View
                </ToggleButton>
                <ToggleButton value={RoomViewType.firstPersonView} aria-label="centered">
                    Front view
                </ToggleButton>
            </ToggleButtonGroup>
        </Box> */}
    </Box >)
}

export default ClientComponent(ControlsBar);